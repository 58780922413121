import GeographicZoneSlug from './types/enums/geographicZoneSlug.enum';

export function getGeographicZonePicto({
  geographicZoneSlug,
  countrySlugs = []
}: {
  geographicZoneSlug: string;
  countrySlugs?: string[];
}) {
  const countrySlugExists = (slug: string) => countrySlugs.includes(slug);

  switch (geographicZoneSlug) {
    case GeographicZoneSlug.EUROPE:
      return 'picto-europe';

    case GeographicZoneSlug.EAST:
      return 'picto-east';

    case GeographicZoneSlug.AFRICA_AND_INDIAN_OCEAN:
      return 'picto-africa-and-indian-ocean';

    case GeographicZoneSlug.ASIA:
      if (['chine', 'taiwan', 'mongolie', 'coree-du-nord'].some(countrySlugExists)) {
        return 'picto-china';
      }
      if (['inde', 'sri-lanka', 'maldives', 'bhoutan', 'nepal'].some(countrySlugExists)) {
        return 'picto-india';
      }
      return 'picto-asia';

    case GeographicZoneSlug.AUSTRALIA_AND_PACIFIC_ISLANDS:
      return 'picto-australia-and-pacific-islands';

    case GeographicZoneSlug.AMERICA:
      if (['canada', 'etats-unis', 'bahamas', 'jamaique'].some(countrySlugExists)) {
        return 'picto-north-america';
      }
      return 'picto-south-america';

    default:
      return undefined;
  }
}
